exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-malaysia-js": () => import("./../../../src/pages/malaysia.js" /* webpackChunkName: "component---src-pages-malaysia-js" */),
  "component---src-pages-modern-slavery-js": () => import("./../../../src/pages/modern-slavery.js" /* webpackChunkName: "component---src-pages-modern-slavery-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-specialisms-architecture-js": () => import("./../../../src/pages/specialisms/architecture.js" /* webpackChunkName: "component---src-pages-specialisms-architecture-js" */),
  "component---src-pages-specialisms-bi-data-analytics-js": () => import("./../../../src/pages/specialisms/bi-data-analytics.js" /* webpackChunkName: "component---src-pages-specialisms-bi-data-analytics-js" */),
  "component---src-pages-specialisms-communications-js": () => import("./../../../src/pages/specialisms/communications.js" /* webpackChunkName: "component---src-pages-specialisms-communications-js" */),
  "component---src-pages-specialisms-cyber-security-js": () => import("./../../../src/pages/specialisms/cyber-security.js" /* webpackChunkName: "component---src-pages-specialisms-cyber-security-js" */),
  "component---src-pages-specialisms-erp-js": () => import("./../../../src/pages/specialisms/erp.js" /* webpackChunkName: "component---src-pages-specialisms-erp-js" */),
  "component---src-pages-specialisms-infrastructure-cloud-services-js": () => import("./../../../src/pages/specialisms/infrastructure-cloud-services.js" /* webpackChunkName: "component---src-pages-specialisms-infrastructure-cloud-services-js" */),
  "component---src-pages-specialisms-leadership-js": () => import("./../../../src/pages/specialisms/leadership.js" /* webpackChunkName: "component---src-pages-specialisms-leadership-js" */),
  "component---src-pages-specialisms-software-engineering-devops-js": () => import("./../../../src/pages/specialisms/software-engineering-devops.js" /* webpackChunkName: "component---src-pages-specialisms-software-engineering-devops-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

